class MFWKError extends Error {
  constructor(message) {
    super(message);
    this.name = 'MFWKError';
  }
}
class MError extends Error {
  constructor(message, data) {
    super(message);
    this.name = 'MError';
    this.data = data;
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { MError, MFWKError };
